import * as React from "react";
import {createContext, useContext} from "react";
import Cookies from "universal-cookie";
import {User} from "../../model/User";
import {JwtToken} from "../../model/JwtToken";
import {Role} from "../../model/Role";
import {useAuthApiClient} from "../../clients/AuthApiClient";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {idProvider} from "../../clients/ApiClient";

export const EXPIRY_TIME = 60 * 60 * 1000; // 1 hour * 60 minutes * 60 seconds * 1000 millis
// export const EXPIRY_TIME = 10 * 1000 // 1 hour * 60 minutes * 60 seconds * 1000 millis

export const EXPIRY_OFFSET = EXPIRY_TIME / 10; // refresh token if 90% of the time is gone

export const getExpiryDateFromNow = () =>
    new Date(new Date().getTime() + EXPIRY_TIME);

export const checkAccessforUser = (user?: User, roles?: Role[]) => {
    if (!user) {
        return false;
    }
    for (let i = 0; i < (roles?.length ?? 0); i++) {
        for (let j = 0; j < (user.roles?.length ?? 0); j++) {
            if (roles![i] === user.roles![j]) {
                return true;
            }
        }
    }
    return false;
};

export interface UserContext {
    user?: User;
    getProfilePicture(): string;
    setTokenFromCookies(): void;
    isLoggedIn(): boolean;
    login(token: JwtToken): void;
    loginGoogle(googleToken: string, profilePicture?: string): void;
    loginFacebook(facebookToken: string, profilePicture?: string): void;
    logout(reload?: boolean): void;
    setCurrentUser(user: User): void;
    refreshToken(): void;
    verifyToken(): Promise<boolean>;
    userHasAccess(...roles: Role[]): boolean;
}

const defaultUserContext: UserContext = {
    getProfilePicture: function (): string {
        throw new Error("Function not implemented.");
    },
    setTokenFromCookies: function (): void {
        throw new Error("Function not implemented.");
    },
    isLoggedIn: function (): boolean {
        throw new Error("Function not implemented.");
    },
    login: function (_token: JwtToken): void {
        throw new Error("Function not implemented.");
    },
    loginGoogle: function(_googleToken: string, _profilePicture?: string): void {
        throw new Error("Function not implemented.");
    },
    loginFacebook: function(_facebookToken: string, _profilePicture?: string): void {
        throw new Error("Function not implemented.");
    },
    logout: function (): void {
        throw new Error("Function not implemented.");
    },
    verifyToken: function (): Promise<boolean> {
        throw new Error("Function not implemented.");
    },
    setCurrentUser: function (user: User): void {
        throw new Error("Function not implemented.");
    },
    userHasAccess: function (...roles: Role[]): boolean {
        throw new Error("Function not implemented.");
    },
    refreshToken: function (): void {
        throw new Error("Function not implemented.");
    },
};

export const UserContextHolder = createContext<UserContext>(defaultUserContext);

export const useUserContext = () => {
    return useContext(UserContextHolder);
};

// Define the shape of your context
interface FileContextType {
    files: string[]; // List of files
    addFile: (fileNameAndPath: string, content: ArrayBuffer) => void; // Function to add a file
    removeFile: (fileNameAndPath: string) => void; // Function to remove a file
}

// Create the Context
const FileContext = createContext<FileContextType | null>(null);

// Custom Hook to use FileContext easily
export const useFileContext = () => {
    const context = useContext(FileContext);
    if (!context) {
        throw new Error("useFileContext must be used within a FileProvider");
    }
    return context;
};

export interface IUserContextProps {}

export const UserContextProvider: React.FunctionComponent<
    React.PropsWithChildren<IUserContextProps>
> = (props: React.PropsWithChildren<IUserContextProps>) => {
    const authApiClient = useAuthApiClient();
    const cookies = new Cookies();

    const [user, setUser] = React.useState<User>();

    // files the user wanted to chat with, they are stored in the local storage
    const [files, setFiles] = React.useState<string[]>([]);

    // Function to load files from localStorage
    const loadFilesFromLocalStorage = () => {
        const keys = Object.keys(localStorage).filter((key) => key.startsWith("/faissUsrUploads/"));
        setFiles(keys); // Set the initial files state
    };

    React.useEffect(() => {
        loadFilesFromLocalStorage(); // Load files on initial render

        // Listen for changes in localStorage from other tabs
        const handleStorageEvent = (event: StorageEvent) => {
            loadFilesFromLocalStorage();
        };

        // The storage event fires when a change is made to localStorage from another browser tab or window, 
        // not within the same tab where the code is running.
        window.addEventListener("storage", handleStorageEvent);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("storage", handleStorageEvent);
        };
    }, []);

    // Function to add a file
    const addFile = (fileNameAndPath: string, content: ArrayBuffer) => {
        localStorage.setItem(
            fileNameAndPath,
            JSON.stringify([...new Uint8Array(content)])
        );
        setFiles((prevFiles) => [...prevFiles, fileNameAndPath]); // Add new file to the array
    };

    // Function to remove a file
    const removeFile = (fileNameAndPath: string) => {
        localStorage.removeItem(fileNameAndPath);
        // Remove file from the array
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileNameAndPath)); 
    };
    

    const refreshTokenInterval = React.useRef<NodeJS.Timeout | null>(null);

    const getRefreshToken = () => {
        return cookies.get("refresh-token");
    };
    const setRefreshToken = (refreshToken: string) => {
        cookies.set("refresh-token", refreshToken, {
            path: "/",
            maxAge: EXPIRY_TIME * 24,
            sameSite: "strict",
        });
    };
    const getToken = () => {
        return cookies.get("token");
    };
    const setToken = (token: string) => {
        cookies.set("token", token, {
            path: "/",
            expires: getExpiryDateFromNow(),
            maxAge: EXPIRY_TIME,
            sameSite: "strict",
        });
    };
    
    const setIdProvider = (idProvider: string) => {
        cookies.set("x-id-provider", idProvider, {
            path: "/",
            sameSite: "strict",
        });
    };
    
    const setProfilePicture = (profilePicture: string) => {
        cookies.set("bb-profile-picture", profilePicture, {
            path: "/",
            sameSite: "strict",
        });
    };
    
    const getProfilePicture = () => {
        const pic:string = cookies.get("bb-profile-picture")
        if (pic !== undefined) {
            return pic
        } else {
            return ""
        }
    }

    /* const setCookiesFromToken = (jwtToken: JwtToken) => {
        setToken(jwtToken.token);
        setRefreshToken(jwtToken.refreshToken);
    };*/

    const setTokenFromCookies = () => {
        const token = cookies.get("token");
        const refreshToken = cookies.get("refresh-token");
        if (token && refreshToken) {
            const jwtToken: JwtToken = { token, refreshToken };
            startRefresh(jwtToken);
        }
    };

    const logout = (reload?: boolean) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("refresh-token", { path: "/" });
        cookies.remove("x-id-provider", { path: "/" });
        cookies.remove("bb-profile-picture", { path: "/" });
        if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
        }
        setUser(undefined);
        refreshTokenInterval.current = null;
        if (reload) {
            window.location.reload();
        }
    };

    const refreshToken = (token?: JwtToken) => {
        const cookieToken = getRefreshToken();
        if (token || cookieToken) {
            authApiClient
                .refresh(token?.refreshToken ?? cookieToken)
                .then((jwt: JwtToken) => {
                    login(jwt);
                })
                .catch(() => logout());
        }
    };

    const startRefresh = (token: JwtToken) => {
        if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
        }
        refreshTokenInterval.current = setInterval(
            () => refreshToken(token),
            EXPIRY_TIME - EXPIRY_OFFSET
        );
    };

    const isLoggedIn = (): boolean => {
        const jwtToken = getToken();
        return !!(user && jwtToken);
    };

    const login = (jwtToken: JwtToken) => {
        setToken(jwtToken.token);
        setRefreshToken(jwtToken.refreshToken);
        setIdProvider(idProvider.BOTBUCKET)
        // setCookiesFromToken(jwtToken);
        startRefresh(jwtToken);
    };
    
    const loginGoogle = (googleToken: string, profilePicture?: string) => {
        setToken(googleToken)
        setIdProvider(idProvider.GOOGLE)
        setProfilePicture(profilePicture || "")
        setRefreshToken("")
    }
    
    const loginFacebook = (facebookToken: string, profilePicture?: string) => {
        setToken(facebookToken)
        setIdProvider(idProvider.FACEBOOK)
        setProfilePicture(profilePicture || "")
        setRefreshToken("")
    }
    
    const setCurrentUser = (user: User) => {
        setUser(user);
    };

    const verifyToken = async (): Promise<boolean> => {
        if (!user) {
            return false;
        }
        return authApiClient
            .isAuthorized()
            .then(() => true)
            .catch(async () => {
                //if we run into any issue, we want to try to refresh the token, otherwise invalidate the user context and jwt token
                const refreshToken = getRefreshToken();
                if (refreshToken) {
                    await authApiClient
                        .refresh(refreshToken)
                        .then((jwt: JwtToken) => {
                            login(jwt);
                        })
                        .catch(() => logout());
                    return getToken() !== undefined;
                } else {
                    logout();
                    return false;
                }
            });
    };

    const userHasAccess = (...roles: Role[]): boolean => {
        return checkAccessforUser(user, roles);
    };

    const userCtx: UserContext = {
        user: user,
        getProfilePicture,
        setTokenFromCookies,
        isLoggedIn,
        login,
        loginGoogle,
        loginFacebook,
        logout,
        setCurrentUser,
        verifyToken,
        userHasAccess,
        refreshToken,
    };

    return (
        <>
            <UserContextHolder.Provider value={userCtx}>
                <GoogleOAuthProvider clientId="300372361177-79oeem0t9ei4at3gneipcud8h11mtgii.apps.googleusercontent.com">
                    <FileContext.Provider value={{ files, addFile, removeFile }}>
                        {props.children}
                    </FileContext.Provider>
                </GoogleOAuthProvider>
            </UserContextHolder.Provider>
        </>
    );
};
