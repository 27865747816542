
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import AppRouter from "./shared/router/AppRouter";
import { grey } from "@mui/material/colors";
import { UserContextProvider } from "./shared/contexts/UserContext";
import NotificationContextProvider from './shared/contexts/NotificationContext';

// import i18n (needs to be bundled ;))
import './shared/i18n/I18n';
import {HelperBotContextProvider} from "./shared/contexts/HelperBotContext";

import "@mui/material/styles/createPalette";

// 2do: MUI localization (https://mui.com/material-ui/guides/localization/)

// Favicon by https://favicon.io

// palette https://www.colorhunt.co/palette/f2ee9d7a9d54557a468c3333
const theme = createTheme({
    palette: {
        primary: {
            // main: "#557A46", // green
            main: "#2E2963", // dark blue
        },
        secondary: {
            main: "#9E98FB", // yellow
            dark: grey[300]
        },
        background: {
            paper: grey[100]
        },
        error: {
            main: "#ED5EA1", // red
            dark: '#ED5EA1',
            light: '#ED5EA1'
        },
        warning: {
            main: '#9E98FB', //yellow
            light: '#9E98FB'
        },
        success: {
            main: "#9E98FB", //light green
            light: "#9E98FB"
        },
        info: {
            main: grey[900]
        }
    },
    typography: {
        fontFamily: [
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

function App() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <UserContextProvider>
                    <HelperBotContextProvider>
                        <NotificationContextProvider>
                            <CssBaseline />
                            <AppRouter />
                        </NotificationContextProvider>
                    </HelperBotContextProvider>
                </UserContextProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
