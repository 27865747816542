import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';

interface LoginModalProps {
    botId: string;
    isOpen: boolean;
    onClose: () => void;
    mainColor: string;
}

const LoginModal: React.FC<LoginModalProps> = ({ botId, isOpen, onClose, mainColor }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        // Store the username and password in session storage
        sessionStorage.setItem('/' + botId + '/username', username);
        sessionStorage.setItem('/' + botId + '/password', password);

        // Clear the fields (optional)
        setUsername('');
        setPassword('');

        // Close the modal
        onClose();
    };

    return (
        <Modal open={isOpen} 
               onClose={(_, reason) => {
                    // Prevent modal from closing by clicking the backdrop
                    if (reason !== 'backdropClick') onClose();
                }}
               aria-labelledby="login-modal"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                }}
            >
                <Typography id="login-modal" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Login
                </Typography>
                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{ mb: 2 }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleLogin(); // Trigger login on "Enter" key
                    }}
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 3 }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleLogin(); // Trigger login on "Enter" key
                    }}
                />
                <Button variant="contained" fullWidth onClick={handleLogin} 
                        sx={{backgroundColor: mainColor}}
                >
                    Login
                </Button>
            </Box>
        </Modal>
    );
};
export default LoginModal;
