import { FileEntity } from "./FileEntity"
import { User } from "./User"

export interface ChatbotPagination {
    totalResults: number
    page: number
    totalPages: number
    pageSize: number
    chatbots: Chatbot[]
}

export interface Chatbot {
    id: string
    name: string
    description: string
    createdAt?: string
    createdBy?: User
    allowedUsers?: User[]
    settings: ChatbotSettings
    queryCounter?: number
    files?: FileEntity[]
    trainingStatus: TrainingStatus
}

export interface ChatbotUser {
    username: string
    password: string
    isActive: boolean
}

export interface ChatbotSettings {
    publicAvailable?: boolean
    addSources?: boolean
    checkAnswers?: boolean
    temperature?: number
    defaultSystemPrompt?: string
    chunksToFetch?: number // 5;
    maxNewTokens?: number // 1500;
    defaultSystemPromptDe?: string
    defaultSystemPromptEn?: string 
    nlpTask: string // "RAG";
    nlpModel: string
    welcome_rag?: { [key: string]: string[] }

    titleText?: string
    icon?: string
    colors?: { [key: string]: string }
    botOpacity?: number
    descriptions?: { [key: string]: string[] }

    hasWaitMessages?: boolean // use me!
    w8messages?: string[] // old
    w8messages_int?: { [key: string]: string[] } // new (localized) - use me in Chat.tsx!

    web_rag_base_url?: string
    web_rag_city?: string
    web_rag_selectors?: string[]
    web_rag_max_search_results?: number
    
    mirrorIFrameIcon?: boolean
    hideChatIconInIFrame?: boolean
    chatInIFrameInitiallyOpen?: boolean
    hasCloseButtonEnabled?: boolean
    showBookAnimationAboveIFrameIcon?: boolean
    showIFrameHelperText?: boolean

    avatarEnabled?: boolean
    avatarId?: string
    avatarBackgroundUrl?: string
    avatarQuality?: string
    avatarIDCustom?: string

    // CIS
    hasCISpatternEnabled: boolean
    welcome_cis?: { [key: string]: string[] }
    
    // settings
    hasMaliciousQueryProtectionEnabled: boolean
    hasSpeechInputEnabled: boolean
    hasSpeechOutputEnabled: boolean
    hasHalucinationIndicatorEnabled: boolean
    showAdditionalHallucinationWarning: boolean
    hasFeedbackEnabled: boolean
    feedbackEmailAddress: string
    hasAnswerByEmailEnabled: boolean
    ccAddressAnswerByMail: string
    templateAnswerByMail: string
    hasChatCharactersEnabled: boolean
    hasInfoPageEnabled: boolean
    hasDownloadChatAsPDFenabled: boolean
    hasChatWithFilesEnabled: boolean
    hasDefaultQuestionsEnabled: boolean
    defaultQuestions: {[key: string]: string}
    hasUserAuthBeforeChatEnabled: boolean
    
    // data protection
    linkToDataProtectionStatement: string
    hasProtocolChatQueriesEnabled: boolean
    hasCheckChatQueriesCheckForPersonalDataEnabled: boolean
    howToHandleQueriesWithPersonalData: number // 0 = always answer, 1 = ask user and answer, 2 = deny answer
    hasDisableChatQueriesWithPersonalDataExcludedFromProtocolEnabled: boolean
}

export interface ChatbotContextResponse {
    hasRag: boolean
    ragInfos: VectorstoreInfo

    hasRtRag: boolean
    rtragInfos: VectorstoreInfo
    
    hasEvents: boolean
    eventInfos: VectorstoreInfo
    
    hasCis: boolean
    cisInfos: VectorstoreInfo
    interviews: [FileInfo]
    
    hasUserInput: boolean
    usrInfos: VectorstoreInfo
    uploads: [FileInfo]
}

export interface FileInfo {
    filename: string
    size: number
    lastModified: string
    created: string
}

export interface VectorstoreInfo {
    lastModified: string
    num_documents: number
    avg_size_bytes: number
    avg_num_tokens: number
    total_bytes: number 
    total_tokens: number
}

export interface FileToVecstoreResponse {
    blob: Promise<Blob>
    headers: Headers
}

export interface ChatUsageQuery {
    botId: string
    startDateTime: string
    endDateTime: string
}

export interface ChatUsageResponse {
    botId: string
    startDateTime: string
    endDateTime: string
    usageByDay: [ChatQueryAggregation]
    usageByTopic: [ChatQueryAggregation]
    lastQueries: [ChatQueryStat]
}

export interface ChatQueryAggregation {
    date: string
    count: number
}

export interface ChatQueryStat {
    date: string
    about: string
    question: string
    answer: string
}

export const availableAvatars = [
    { name: "Aiden", imageUrl: "/img/avatar/preview/Aiden.png"},
    { name: "Aiden outdoor", imageUrl: "/img/avatar/preview/Aiden_outdoor.png"},
    { name: "Angela-inblackskirt-20220820", imageUrl: "/img/avatar/preview/Angela-inblackskirt-20220820.png"},
    { name: "Angelina in office", imageUrl: "/img/avatar/preview/Angelina_in_office.png"},
    { name: "Angelina outdoor",imageUrl: "/img/avatar/preview/Angelina_outdoor.png"},
    { name: "Anna_public_20240108",imageUrl: "/img/avatar/preview/Anna_public_20240108.png"},
    { name: "Anna_public_3_20240108",imageUrl: "/img/avatar/preview/Anna_public_3_20240108.png"},
    { name: "Briana_public_3_20240110",imageUrl: "/img/avatar/preview/Briana_public_3_20240110.png"},
    { name: "Eric_public_pro2_20230608",imageUrl: "/img/avatar/preview/Eric_public_pro2_20230608.png"},
    { name: "Justin_public_3_20240308",imageUrl: "/img/avatar/preview/Justin_public_3_20240308.png"},
    { name: "Kayla-incasualsuit-20220818",imageUrl: "/img/avatar/preview/Kayla-incasualsuit-20220818.png"},
    { name: "Kristin_public_2_20240108",imageUrl: "/img/avatar/preview/Kristin_public_2_20240108.png"},
    { name: "Lily_public_pro1_20230614",imageUrl: "/img/avatar/preview/Lily_public_pro1_20230614.png"},
    { name: "Monica in Sleeveless",imageUrl: "/img/avatar/preview/Monica_in_Sleeveless.png"},
    { name: "Raj in office",imageUrl: "/img/avatar/preview/Raj_in_office.png"},
    { name: "Raj outdoor",imageUrl: "/img/avatar/preview/Raj_outdoor.png"},
    { name: "Sofia", imageUrl: "/img/avatar/preview/Sofia.png"},
    { name: "Sofia in office", imageUrl: "/img/avatar/preview/Sofia_in_office.png"},
    { name: "Sofia outdoor",imageUrl: "/img/avatar/preview/Sofia_outdoor.png"},
    { name: "Susan_public_2_20240328",imageUrl: "/img/avatar/preview/Susan_public_2_20240328.png"},
    { name: "Tyler-incasualsuit-20220721",imageUrl: "/img/avatar/preview/Tyler-incasualsuit-20220721.png"},
    { name: "Tyler-insuit-20220721",imageUrl: "/img/avatar/preview/Tyler-insuit-20220721.png"},
    { name: "Tyler-inshirt-20220721",imageUrl: "/img/avatar/preview/Tyler-inshirt-20220721.png"},
    { name: "Vicky", imageUrl: "/img/avatar/preview/Vicky.png"},
    { name: "Vicky outdoor",imageUrl: "/img/avatar/preview/Vicky_outdoor.png"},
    { name: "Wayne",imageUrl: "/img/avatar/preview/Wayne.png"},
    { name: "Wade_public_2_20240228",imageUrl: "/img/avatar/preview/Wade_public_2_20240228.png"},
];

export const getAvatarImg = (avatarName: string) => {
    const av = availableAvatars.find((avatar) => avatar.name === avatarName);
    if (av) {
        return av.imageUrl
    }
    return "/img/avatar/preview/default.png"
    
}

export interface FeedbackContent {
    createdAt: string
    feedback: string
    chatPdfAsBase64: string
}

export enum TrainingStatus {
    NOT_TRAINED = 'NOT_TRAINED',
    PENDING = 'PENDING',
    TRAINING = 'TRAINING',
    TRAINED = 'TRAINED',
    FAILED = 'FAILED'
}


export enum Embeddings {
    OPEN_AI_TE_3_LARGE = "OpenAI/text-embedding-3-large",
    OPEN_AI_TE_3_SMALL = "OpenAI/text-embedding-3-small",
    OPEN_AI_TE_ADA_2 = "OpenAI/text-embedding-ada-002",
    SENTENCE_TRANSFORMERS_ALL_MINILM_L6_V2 = "sentence-transformers/all-MiniLM-L6-v2",
    HKUNLP_INSTRUCTOR_LARGE = "hkunlp/instructor-large",
    NOMIC_AI_EMBED_TEXT_1_5 = "nomic-ai/nomic-embed-text-v1.5",
    INTFLOAT_E5_BASE = "intfloat/e5-base",
}

export interface TrainingSettings {
    embeddings: Embeddings
    chunkSize: number
    chunkOverlap: number
}

export interface TranslationRequest {
    textToTranslate: string
    sourceLanguage: string
    targetLanguages: string[]
}

export const botIsTraining = (bot: Chatbot) => {
    return bot.trainingStatus === TrainingStatus.PENDING || bot.trainingStatus === TrainingStatus.TRAINING

}