import React, { useState } from "react";
import { TreeItem } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { CircularProgress, Checkbox } from "@mui/material";
import { RiNodeTree } from "react-icons/ri";

interface TreeNodeProps {
    path: string;
    label: string;
    onSelect: (path: string) => void;
    level: number;
    checkboxColor: string;
}

interface ChildNode {
    path: string;
    label: string;
}

const makePalerRGB = (color: string, factor: number) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const paleR = Math.min(255, r + factor);
    const paleG = Math.min(255, g + factor);
    const paleB = Math.min(255, b + factor);
    return `rgb(${paleR}, ${paleG}, ${paleB})`;
};

const TreeNode: React.FC<TreeNodeProps> = ({ path, label, onSelect, level, checkboxColor }) => {
    const [children, setChildren] = useState<ChildNode[]>([{ path: `${path}/loading`, label: "loading..." }]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const fetchChildren = async () => {
        setIsLoading(true);
        try {
            // Simulating an API call
            const data: ChildNode[] = [
                { path: `${path}/sub1`, label: "sub1" },
                { path: `${path}/sub2`, label: "sub2" },
            ];
            setTimeout(() => setChildren(data), 1000);
        } catch (error) {
            console.error("Failed to fetch children", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggle = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).tagName !== 'INPUT') {
            if (!isOpen && children.length === 1 && children[0].label === "loading...") {
                fetchChildren();
            }
            setIsOpen(!isOpen);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        setIsChecked(event.target.checked);
        onSelect(path);
    };

    return (
        <TreeItem
            itemId={path}
            sx={{
                color: checkboxColor,
                '& .MuiTreeItem-content': {
                    paddingTop: 0, paddingBottom: 0,
                    '&.Mui-selected': {
                        backgroundColor: makePalerRGB(checkboxColor, 180),
                    },
                    '&:hover': {
                        backgroundColor: makePalerRGB(checkboxColor, 180),
                    },
                },
                '& .Mui-selected': {
                    backgroundColor: makePalerRGB(checkboxColor, 180),
                },
                
            }}
            onClick={handleToggle}
            label={
                <div  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                        <span style={{ fontWeight: isChecked ? "bold" : "normal" }}>{label}</span>
                        {isLoading && <CircularProgress size={12} style={{ marginLeft: 8 }} />}
                    </div>
                    <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        onMouseDown={(event) => event.stopPropagation()} // Prevent TreeItem's onClick
                        onClick={(event) => event.stopPropagation()} // Prevent TreeItem's onClick
                        inputProps={{ "aria-label": `Select ${label}` }}
                        sx={{
                            color: checkboxColor,
                            '&.Mui-checked': {
                                color: checkboxColor,
                            },
                        }}
                    />
                </div>
            }
            
        >
            {children.map((child) => (
                child.label === "loading..." ?
                    <TreeItem itemId={`${path}/loading...`} label={<CircularProgress size={20} />} />
                : <TreeNode
                    key={child.path}
                    path={child.path}
                    label={child.label}
                    onSelect={onSelect}
                    level={level + 1}
                    checkboxColor={checkboxColor}
                />
            ))}
        </TreeItem>
    );
};

const HierarchyTree: React.FC<{ checkboxColor: string }> = ({ checkboxColor }) => {
    const [selectedNode, setSelectedNode] = useState<string | null>(null);

    const handleSelect = (path: string) => {
        setSelectedNode(path);
        console.log("Selected node:", path);
    };

    return (
        <div>
            <h3><RiNodeTree /> Tree Structure</h3>
            <SimpleTreeView
                itemChildrenIndentation={"12px"}
                onExpandedItemsChange={(event: React.SyntheticEvent, itemIds: string[]) => {
                    event.stopPropagation()
                }}
            >
                <TreeNode path="/a" label="Root 1" onSelect={handleSelect} level={0} checkboxColor={checkboxColor} />
                <TreeNode path="/b" label="Root 2" onSelect={handleSelect} level={0} checkboxColor={checkboxColor} />
                <TreeNode path="/c" label="Root 3" onSelect={handleSelect} level={0} checkboxColor={checkboxColor} />
            </SimpleTreeView>
            {selectedNode && <div>Selected Node: <strong>{selectedNode}</strong></div>}
        </div>
    );
};

export default HierarchyTree;
