import React, { useState } from "react";
import { IconButton, Tooltip, Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PiFileDoc, PiFileXls, PiFilePdf } from "react-icons/pi";
import {useFileContext} from "../../../shared/contexts/UserContext";

interface FileIconsListProps {
    bot_id: string;
    onEntryEnabledChange: (enabledEntries: string[]) => void;
    contrastColor: string;
}

const FileIconsList: React.FC<FileIconsListProps> = (props: FileIconsListProps) => {

    const { files, removeFile } = useFileContext();
    
    const [enabledEntries, setEnabledEntries] = useState<string[]>([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

    const handleToggleEnable = (entry: string) => {
        const newEnabledEntries = enabledEntries.includes(entry)
            ? enabledEntries.filter((e) => e !== entry)
            : [...enabledEntries, entry];
        setEnabledEntries(newEnabledEntries);
        props.onEntryEnabledChange(newEnabledEntries);
    };

    const handleDownload = (entry: string) => {
        
        const storedData  = localStorage.getItem(entry);
        if (storedData) {
            const fileName = entry.replaceAll("/faissUsrUploads/" + props.bot_id + "/", "")
            const fileType = "application/octet-stream"
            // Parse the stored JSON string back to a Uint8Array
            const byteArray = new Uint8Array(JSON.parse(storedData));
            const blob = new Blob([byteArray], { type: fileType });
            // Recreate the File object
            // const restoredFile = new File([blob], fileName, { type: fileType });

            const url = URL.createObjectURL(blob);

            // Create an <a> element and trigger the download
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName; // File name for the download
            a.style.display = "none"; // Hide the <a> element

            // Append to the document to enable click triggering
            document.body.appendChild(a);

            // Simulate a click to trigger "Save As..." dialog
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url); // Release memory
            
        } else {
            console.error("No file found in localStorage");
        }
    };

    const handleDelete = (entry: string) => {
        console.log(`Delete requested for: ${entry}`);
        removeFile(entry)
    };

    const getIconByExtension = (filename: string) => {
        const extension = filename.replace(".bbz", "").split(".").pop()?.toLowerCase();
        switch (extension) {
            case "pdf":
                return <PiFilePdf />;
            case "jpg":
            case "jpeg":
            case "png":
                return <ImageIcon />;
            case "doc":
                return <PiFileDoc />;
            case "xls":
                return <PiFileXls />;
            case "txt":
                return <InsertDriveFileIcon />
            default:
                return <InsertDriveFileIcon />;
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, entry: string) => {
        setMenuAnchorEl((prev) => ({ ...prev, [entry]: event.currentTarget }));
    };

    const handleMenuClose = (entry: string) => {
        setMenuAnchorEl((prev) => ({ ...prev, [entry]: null }));
    };

    return (
        <Box
            sx={{
                position: "absolute",
                right: 16,
                top: 16,
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            {files.map((entry) => (
                <Box key={entry}>
                    <Tooltip title={entry} placement="left">
                        <IconButton
                            onClick={() => handleToggleEnable(entry)}
                            onContextMenu={(e) => {
                                e.preventDefault(); // Prevent default right-click menu
                                handleMenuOpen(e, entry);
                            }}
                            sx={{
                                border: '1px solid '+ props.contrastColor,
                                backgroundColor: enabledEntries.includes(entry) ? props.contrastColor : "grey.300",
                                color: "white",
                                transition: "transform 0.2s",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            {getIconByExtension(entry)}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        anchorEl={menuAnchorEl[entry]}
                        open={Boolean(menuAnchorEl[entry])}
                        onClose={() => handleMenuClose(entry)}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem onClick={() => handleToggleEnable(entry)}>
                            <ListItemIcon>
                                {enabledEntries.includes(entry) ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />}
                            </ListItemIcon>
                            <ListItemText>
                                {enabledEntries.includes(entry) ? "Deactivate" : "Activate"}
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => handleDownload(entry)}>
                            <ListItemIcon>
                                <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText>Store locally</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(entry)}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
            ))}
        </Box>
    );

};

export default FileIconsList;
