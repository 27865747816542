import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {ChangeEvent, useState} from 'react';
import {
    Alert, Checkbox, Dialog, DialogActions, DialogContent, Fab,
    Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AllowUserSetting from '../chatbot/components/AllowUserSetting';
import DialogHeader from "../../shared/components/dialogs/DialogHeader";
import {Chatbot, ChatbotSettings, ChatbotUser} from "../../model/Chatbot";
import {useChatbotApiClient} from "../../clients/ChatbotApiClient";
import {ErrorResponse} from "../../clients/error/ErrorResponse";
import Paper from "@mui/material/Paper";
import {Add, Delete} from "@mui/icons-material";
import Headline from "../../shared/components/Headline";


export interface IUserSettingsModalProps {
    chatbot: Chatbot
    open?: boolean
    onClose?(): void
    onBotChange?(updatedBot: Chatbot): void
}

const UserSettingsModal: React.FunctionComponent<IUserSettingsModalProps> = (props: IUserSettingsModalProps) => {
    
    const chatbotClient = useChatbotApiClient({ preventNotification: true })

    const { t } = useTranslation()

    const [pageError, setPageError] = useState<string>()

    const [data, setData] = useState<ChatbotSettings>(props.chatbot.settings!);
    const [chatUserListLoading, setChatUserListLoading] = useState(true)
    const [savingLoading, setSavingLoading] = useState(false)
    const [allowedBotUsers, setAllowedBotUsers] = useState<ChatbotUser[]>([])

    // State for new user input
    const [newUser, setNewUser] = useState<Partial<ChatbotUser>>({
        username: "",
        password: "",
        isActive: true, // Default value
    });

    const handleAddUser = () => {
        if (newUser.username && newUser.password) {
            setAllowedBotUsers((prevUsers) => [
                ...prevUsers,
                { ...newUser, isActive: !!newUser.isActive } as ChatbotUser, // Add new user
            ]);
            setNewUser({ username: "", password: "", isActive: true }); // Reset input fields
        } else {
            alert("Please fill in both username and password.");
        }
    };
    
    const getChatbotAccessUsers = () => {
        chatbotClient.getChatbotAccessUsers(props.chatbot.id)
            .then((res: any) => {
                const users: ChatbotUser[] = res.chatbotUsers 
                if (Array.isArray(users)) {
                    setAllowedBotUsers(users);
                } else {
                    setAllowedBotUsers([]); // Fallback to an empty array
                }
                setChatUserListLoading(false)
            })
            .catch((err: ErrorResponse) => {
                setPageError(err.message)
            })
            .finally(() => setSavingLoading(false))
    }

    const onClose = () => {
        setPageError(undefined)
        props.onClose && props.onClose()
    }
    
    const saveBotUserSettings = () => {
        
        setSavingLoading(true)        
        
        // save users
        chatbotClient.updateChatbotAccessUsers(props.chatbot.id, allowedBotUsers)
            .then((res: string) => console.log("user Save ok: "+ res))
            .catch((err: ErrorResponse) => {
                setPageError(err.message)
            })
            .finally(() => setSavingLoading(false))

        // save regular settings
        const data_copy = JSON.parse(JSON.stringify(data))

        chatbotClient.setChatbotSettings(props.chatbot.id, data_copy)
            .then((bot) => props.onBotChange && props.onBotChange(bot))
            .catch((err: ErrorResponse) => {
                debugger;
                setPageError(err.message)
            })
            .finally(() => setSavingLoading(false))

    }

    const handleRemoveUser = (usernameToRemove: string) => {
        setAllowedBotUsers((prevUsers) =>
            prevUsers.filter((user) => user.username !== usernameToRemove)
        );
    }

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    React.useEffect(() => {
        getChatbotAccessUsers()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'md'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={"User-Settings"}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }

                        {/* users allowed to chat with this bot */}
                        <Grid item xs={12} justifyContent='right' sx={{ marginBottom: '0.5em'}}>
                            <Headline title={"Chatten nur nach Login"} size='section'/>
                        </Grid>

                        <Grid item xs={1} container direction="column" justifyContent="top" alignItems="center">
                            <Switch checked={data.hasUserAuthBeforeChatEnabled} onChange={handleSwitchChange} 
                                    name="hasUserAuthBeforeChatEnabled" 
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography><b>Benutzer-Authentifizierung aktivieren</b><br/>
                                Aktiviert die Benutzerauthentifizierung bevor ein Chatbot verwendet werden kann.
                                Erstellen Sie unten Nutzer. Achtung: Das Passwort kann nicht erneut eingesehen werden. 
                                Bei Bedarf können Sie den Nutzer löschen und einen neuen Nutzer unter 
                                gleichem Namen und neuem Passwort anlegen.
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12} sx={{marginTop: "25px"}}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('chatbot.settingsModal.username')}</TableCell>
                                            <TableCell>Password</TableCell>
                                            <TableCell>Active?</TableCell>
                                            <TableCell>{t('chatbot.settingsModal.actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {chatUserListLoading && <>loading...</>}
                                        {
                                            allowedBotUsers.map((user: ChatbotUser, index: number) => (
                                                <TableRow key={user.username}>
                                                    <TableCell>{user.username}</TableCell>
                                                    <TableCell>•••••••••••</TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={user.isActive}
                                                            onChange={(e) => {
                                                                // Update the `isActive` state for the specific user
                                                                const updatedUsers = [...allowedBotUsers];
                                                                updatedUsers[index] = {
                                                                    ...user,
                                                                    isActive: e.target.checked,
                                                                };
                                                                setAllowedBotUsers(updatedUsers);
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleRemoveUser(user.username)}
                                                            title={t('chatbot.settingsModal.removeUser')}
                                                        >
                                                            <Delete/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                        {/* Row for adding a new user */}
                                        <TableRow>
                                            <TableCell>
                                                <TextField size="small" value={newUser.username} placeholder="Enter username"
                                                    onChange={(e) =>
                                                        setNewUser((prev) => ({
                                                            ...prev,
                                                            username:
                                                            e.target.value,
                                                        }))
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField size="small" type="password" value={newUser.password} 
                                                    placeholder="Enter password"
                                                    onChange={(e) =>
                                                        setNewUser((prev) => ({
                                                            ...prev,
                                                            password:
                                                            e.target.value,
                                                        }))
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={!!newUser.isActive}
                                                    onChange={(e) =>
                                                        setNewUser((prev) => ({
                                                            ...prev,
                                                            isActive:
                                                            e.target.checked,
                                                        }))
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Fab
                                                    color="primary"
                                                    aria-label="add"
                                                    onClick={handleAddUser}
                                                >
                                                    <Add/>
                                                </Fab>
                                            </TableCell>
                                        </TableRow>                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        
                        {/* Allow Admin users */}
                        <Grid item xs={12} sx={{marginTop: "15px"}}>
                            <AllowUserSetting chatBot={props.chatbot} onBotChange={props.onBotChange} />
                        </Grid>
                        
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <LoadingButton loading={savingLoading} onClick={() => saveBotUserSettings()} variant="contained">
                                {"Save"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserSettingsModal;